import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import { PageProps } from "gatsby";
import RichTextComp from "../components/RichTextComp";
import BannerComp from "../components/Banner";
import styles from "../styles/article.module.scss";
import Seo from "../components/Seo";
import ContentMenuComp from "../components/ContentMenu";
import { Typography, Taco, Breadcrumb } from "@cncxt/imodium-component-library";
import { randomNumber } from "../utils/miscFunctions";

interface ArticleDetailsInterface extends PageProps {
  pageContext: {
    uuid: string;
    urlPath: string;
    title: string;
    pageType: string;
    components: string;
    footerData: string;
    meta: {
      property_key_name: string;
      property_key_value: string;
      content: string;
    }[];
    canonical: {
      ref: string;
      href: string;
    };
    hreflang: {
      rel: string;
      hreflang: string;
      href: string;
    }[];
    breadcrumbs: {
      title: string;
      link: string;
    }[];
    relatedContent: string;
  };
}

const ArticleDetails = (data: ArticleDetailsInterface): JSX.Element => {
  const dataContext = data.pageContext;
  const pathname = data.location.pathname;
  const componentData = JSON.parse(dataContext.components);
  const footerData = dataContext.footerData;
  const articleCompObj = componentData.find(
    data => data.type === "article_detail",
  );
  const articleContent = articleCompObj.content;
  const bannerData = {
    shareTitle: "",
    content: {
      image: articleContent.image,
      content: {
        title: articleContent.title,
        description: articleContent.description,
      },
    },
  };
  const sidebarCompObj = componentData.find(data => data.type === "sidebar");
  const sidebarContent =
    sidebarCompObj != undefined ? sidebarCompObj.content : [];
  const shortBody = articleContent.short_body;
  const relatedContentArray = JSON.parse(dataContext.relatedContent);
  const relatedContentData = [];
  if (relatedContentArray.length > 0) {
    relatedContentArray.map(data => {
      const artObj = data.data.components.find(
        obj => obj.type === "article_detail",
      );
      if (artObj.content.call_to_action) {
        relatedContentData.push(artObj.content);
      }
    });
  }
  const contentLength = relatedContentData.length;
  const breadcrumbList =
    dataContext.breadcrumbs !== null
      ? dataContext.breadcrumbs.map(path => {
          return { label: path.title, link: path.link };
        })
      : [];
  return (
    <DefaultLayout siteFooterData={footerData}>
      <Seo
        metaArray={dataContext.meta}
        canonicalArray={dataContext.canonical}
        hreflangArray={dataContext.hreflang}
      />
      {dataContext.breadcrumbs !== null && (
        <Breadcrumb listItems={breadcrumbList} variation={"linear"} />
      )}
      <div className={styles.articleDetailLayout}>
        <BannerComp content={bannerData.content} width="100%" />
        <div className={styles.textContentRow}>
          <div className={styles.textContentWrapper}>
            {typeof articleContent.body !== "string" && (
              <RichTextComp content={articleContent.body} width="100%" />
            )}
            <div className={styles.shortBodyContent}>
              {shortBody.map((shortObj, index) => (
                <div key={randomNumber + index}>
                  <Typography variant="h2" color="ternary">
                    {shortObj.title}
                  </Typography>
                  {typeof shortObj.body !== "string" && (
                    <RichTextComp content={shortObj.body} width="100%" />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.sidebarWrapper}>
            <ContentMenuComp
              content={sidebarContent}
              pathname={pathname}
              width="100%"
            />
          </div>
        </div>
        <div className={styles.relatedList}>
          {relatedContentData.map((relatedData, index) => (
            <div
              key={index}
              className={
                contentLength >= 3
                  ? styles.tacoOuterWrapperThree
                  : styles.tacoOuterWrapper
              }
            >
              <div className={styles.tacoWrapper}>
                <Taco
                  type="vertical"
                  size="fullWidth"
                  title={relatedData.call_to_action.title}
                  desc={relatedData.short_description}
                  image={relatedData.call_to_action.image.src}
                  linkUrl={relatedData.call_to_action.link}
                  linkLabel={relatedData.call_to_action.title}
                  bgColor="#e9f4fa"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ArticleDetails;
